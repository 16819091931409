import {
  Button,
  Col,
  ConfigProvider,
  Image,
  Input,
  Layout,
  Row,
  Spin,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../contexts/Auth";
import { useBrandContext } from "../contexts/Brand";
import { useNavigate } from "react-router-dom";
import styles from "../styles/LoginForm.module.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths } from "../const/const_functions";
import { useServer } from "../contexts/Server";
import LoginSubText from "../components/LoginSubText";
import LoginTitle from "../components/LoginTitle";

const LOGIN_STEPS = {
  LOGIN: 1,
  FORGOT_PASSWORD: 2,
  VERIFY_CODE: 3,
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [tempEmail, setTempEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [loginStep, setLoginStep] = useState(LOGIN_STEPS.LOGIN);
  const { loginWithUsernameAndPassword, authData } = useAuth();
  const { brandContextData } = useBrandContext();
  const navigate = useNavigate();
  const { serverData } = useServer();
  const { theme } = useContext(ConfigProvider.ConfigContext);

  useEffect(() => {
    if (authData) {
      navigate("/edit-account");
    }
  }, [authData]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return typeof password === "string" && password.length >= 6;
  };

  const loginUser = async () => {
    if (!tempEmail) {
      message.error("Please input an email");
      return;
    }

    if (!password) {
      message.error("Please input a password");
      return;
    }

    try {
      await loginWithUsernameAndPassword({
        email: tempEmail,
        username: `${tempEmail}++||++${brandContextData._id}`,
        password,
      });

      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  const sendVerificationCode = async () => {
    const email = tempEmail.toLowerCase();
    const requestFrom = "whitelabel-web";
    const brandContextId = brandContextData._id;
    setSendingCode(true);
    try {
      await serverData.call("USSR_requestPasswordResetCode", {
        email,
        requestFrom,
        brandContextId,
      });
      message.success("A verification code has been sent to your email!", 5);
      setSendingCode(false);
      setLoginStep(LOGIN_STEPS.VERIFY_CODE);
    } catch (e) {
      message.error(
        "The email address you entered is incorrect or does not exist!",
        5
      );
      setSendingCode(false);
    }
  };

  const resetPassword = async (passedCode, passedPassword) => {
    if (passwordError) {
      return;
    }
    const code = passedCode;
    const passwordSubmit = passedPassword;
    const email = tempEmail.toLowerCase();
    const brandContextId = brandContextData._id;
    try {
      await serverData.call("USSR_submitPasswordResetCode", {
        code,
        email,
        brandContextId,
        password: passwordSubmit,
      });
      message.success("Password reset successfully! Please login.", 5);
      setVerificationCodeError(false);
      setPasswordError(false);
      setLoginStep(LOGIN_STEPS.LOGIN);
    } catch (e) {
      message.error("Security code has expired", 5);
      setVerificationCodeError(true);
    }
  };

  let pageTitle = `Login`;
  let pageSubtitle = `Login to update your account information.`;
  let enterButtonText = `Login`;
  let passwordText = `Password`;
  const emailErrorText = `Please input a valid email`;
  const verificationCodeErrorText = `Invalid code`;
  const passwordErrorText = `Please input a password at least 6 characters long`;
  if (loginStep === LOGIN_STEPS.FORGOT_PASSWORD) {
    pageTitle = `Forgot Password`;
    pageSubtitle = `Please enter your email and we'll send you a code to reset your password.`;
    enterButtonText = `Send Verification Email`;
  } else if (loginStep === LOGIN_STEPS.VERIFY_CODE) {
    pageTitle = `Reset Password`;
    pageSubtitle =
      "Please enter the verification code sent to your email. If you haven't received an email in 5 minutes, check your spam or resend your code below";
    enterButtonText = `Confirm`;
    passwordText = `New Password`;
  }

  useEffect(() => {
    if (loginStep === LOGIN_STEPS.LOGIN) {
      setTempEmail("");
      setEmailError(false);
    }
    setPassword("");
    setPasswordError(false);
    setVerificationCode("");
    setVerificationCodeError(false);
  }, [loginStep]);

  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Spin
        size="large"
        spinning={loading || sendingCode}
        tip={loading ? "Logging In." : "Sending Code..."}
      >
        <Content
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
            {brandContextData?.brandLogo ? (
              <Row justify={"center"} className={styles.logoContainer}>
                <Image
                  width={mobile ? "50%" : 200}
                  preview={false}
                  src={brandContextData.brandLogo}
                  style={{
                    maxHeight: 180,
                    minHeight: 75,
                    objectFit: "contain",
                  }}
                />
              </Row>
            ) : null}
            <p
              style={{
                fontWeight: "600",
                color: "black",
                fontSize: 24,
                marginBottom: 0,
              }}
            >
              {pageTitle}
            </p>
            <p
              style={{
                fontWeight: "500",
                color: "grey",
                marginBottom: 40,
                fontSize: 14,
                marginTop: 4,
              }}
            >
              {pageSubtitle}
            </p>
            {loginStep === LOGIN_STEPS.LOGIN ||
            loginStep === LOGIN_STEPS.FORGOT_PASSWORD ? (
              <LoginTitle titleText={`E-mail`} required={true} />
            ) : null}
            {loginStep === LOGIN_STEPS.LOGIN ||
            loginStep === LOGIN_STEPS.FORGOT_PASSWORD ? (
              <Input
                className={styles.input}
                style={{ "--border-color": theme.token.colorSecondary }}
                value={tempEmail}
                defaultValue={null}
                onChange={(e) => {
                  if (
                    (e.target.value && validateEmail(e.target.value)) ||
                    !e.target.value
                  ) {
                    setTempEmail(e.target.value);
                    setEmailError(false);
                  } else {
                    setTempEmail(e.target.value);
                    setEmailError(true);
                  }
                }}
              />
            ) : null}
            {loginStep === LOGIN_STEPS.LOGIN ||
            loginStep === LOGIN_STEPS.FORGOT_PASSWORD ? (
              <LoginSubText showError={emailError} errorText={emailErrorText} />
            ) : null}
            {loginStep === LOGIN_STEPS.VERIFY_CODE ? (
              <LoginTitle titleText={`Verification Code`} required={true} />
            ) : null}
            {loginStep === LOGIN_STEPS.VERIFY_CODE ? (
              <Input
                className={styles.input}
                style={{ "--border-color": theme.token.colorSecondary }}
                value={verificationCode}
                defaultValue={null}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                }}
                maxLength={10}
              />
            ) : null}
            {loginStep === LOGIN_STEPS.VERIFY_CODE ? (
              <LoginSubText
                showError={verificationCodeError}
                errorText={verificationCodeErrorText}
                showSubText={loginStep === LOGIN_STEPS.VERIFY_CODE}
                onClickAction={() => setLoginStep(LOGIN_STEPS.FORGOT_PASSWORD)}
                subText={`Resend Code`}
              />
            ) : null}
            {loginStep === LOGIN_STEPS.LOGIN ||
            loginStep === LOGIN_STEPS.VERIFY_CODE ? (
              <LoginTitle titleText={passwordText} required={true} />
            ) : null}
            {loginStep === LOGIN_STEPS.LOGIN ||
            loginStep === LOGIN_STEPS.VERIFY_CODE ? (
              <Input
                className={styles.input}
                style={{ "--border-color": theme.token.colorSecondary }}
                value={password}
                defaultValue={null}
                type="password"
                onChange={(e) => {
                  if (
                    (e.target.value && validatePassword(e.target.value)) ||
                    !e.target.value
                  ) {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  } else {
                    setPassword(e.target.value);
                    setPasswordError(true);
                  }
                }}
              />
            ) : null}
            {loginStep === LOGIN_STEPS.LOGIN ||
            loginStep === LOGIN_STEPS.VERIFY_CODE ? (
              <LoginSubText
                showError={passwordError}
                errorText={passwordErrorText}
                showSubText={loginStep === LOGIN_STEPS.LOGIN}
                subText={"Forgot Password?"}
                onClickAction={() => setLoginStep(LOGIN_STEPS.FORGOT_PASSWORD)}
              />
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent:
                  loginStep !== LOGIN_STEPS.LOGIN
                    ? "space-between"
                    : "flex-end",
                marginTop: 22,
              }}
            >
              {loginStep !== LOGIN_STEPS.LOGIN ? (
                <Button
                  className={styles.loginBtn}
                  style={{ "--border-color": theme.token.colorSecondary }}
                  onClick={() => setLoginStep(LOGIN_STEPS.LOGIN)}
                >
                  Back to login
                </Button>
              ) : null}
              <Button
                className={styles.loginBtn}
                style={{
                  paddingLeft: 55,
                  paddingRight: 55,
                  "--border-color": theme.token.colorSecondary,
                }}
                onClick={() => {
                  if (loginStep === LOGIN_STEPS.LOGIN) {
                    loginUser();
                  } else if (loginStep === LOGIN_STEPS.FORGOT_PASSWORD) {
                    sendVerificationCode();
                  } else if (loginStep === LOGIN_STEPS.VERIFY_CODE) {
                    resetPassword(verificationCode, password);
                  }
                }}
              >
                {enterButtonText}
              </Button>
            </div>
          </Col>
        </Content>
      </Spin>
    </Layout>
  );
};

export default Login;
