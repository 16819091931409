import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

const MerchantBanner = ({ mobile, jumbotronData }) => {
  const clickedJumboItem = (index) => {
    const clickedItem = jumbotronData[index];

    if (clickedItem.jumboNavUrl !== "") {
      window.open(clickedItem.jumboNavUrl);
    }
  };

  return (
    <Carousel
      onClickItem={(i, item) => clickedJumboItem(i)}
      showThumbs={false}
      autoPlay={true}
      infiniteLoop={true}
      dynamicHeight={true}
      transitionTime={400}
      interval={4000}
    >
      {jumbotronData.map((item, index) => {
        return (
          <div
            key={index}
            style={{ cursor: item.jumboNavUrl ? "pointer" : "default" }}
          >
            <img
              src={item.jumboImageUrl}
              alt="Banner"
              style={{ maxHeight: 400, objectFit: "contain" }}
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default MerchantBanner;
